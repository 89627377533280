/**
 * sponsor state
 * @author Perlou(perloukevin@gmail.com)
 */

import { ref, computed } from 'vue'
import { useEnhancer } from '/@/app/enhancer'
import { ProviderId, GAEventCategories } from '/@/constants/enums'

export const PROVIDER_IDS = Object.values(ProviderId)
export const PROVIDERS = [
    {
        id: ProviderId.Alipay,
        title: '支付宝',
        text: '通过支付宝客户端扫码',
        logo: '/images/third-party/alipay-logo.svg',
        qrcode: '/images/third-party/alipay-qrcode.webp'
    },
    {
        id: ProviderId.WeChatPay,
        title: '微信',
        text: '通过微信客户端扫码',
        logo: '/images/third-party/wechat-pay-logo.svg',
        qrcode: '/images/third-party/wechat-pay-qrcode.webp'
    }
]

export type SponsorState = ReturnType<typeof useSponsorState>
export const useSponsorState = (initId?: ProviderId) => {
    const { gtag } = useEnhancer()
    const activeId = ref(initId && PROVIDER_IDS.includes(initId) ? initId : PROVIDERS[0].id)
    const activeProvider = computed(() => PROVIDERS.find((t) => t.id === activeId.value)!)

    const setProviderId = (id: ProviderId) => {
        if (PROVIDER_IDS.includes(id)) {
            activeId.value = id
            gtag?.event('sponsor_tab_switch', {
                event_category: GAEventCategories.Widget
            })
        }
    }

    return {
        activeId,
        activeProvider,
        setProviderId
    }
}
