/**
 * App layout
 * @author Perlou(perloukevin@gmail.com)
 */

import { RouteMeta } from 'vue-router'
import { LayoutColumn } from '@/constants/enums'

export const getLayoutByRouteMeta = (routeMeta: RouteMeta) => {
    return routeMeta.layout === LayoutColumn.Wide
        ? LayoutColumn.Wide
        : routeMeta.layout === LayoutColumn.Full
          ? LayoutColumn.Full
          : LayoutColumn.Normal
}
