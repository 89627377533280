/**
 * Statistic state
 * @author Perlou(perloukevin@gmail.com)
 */

import { defineStore } from 'pinia'
import { createFetchStore } from './_fetch'
import { TunnelModule } from '/@/constants/enums'
import nodepress from '/@/services/nodepress'
import tunnel from '/@/services/tunnel'

export const useNodepressStatisticStore = defineStore('nodepressStatistic', () => {
    return createFetchStore<Types.expansion.Statistic | null>({
        data: null,
        fetcher: async () => {
            const response = await nodepress.get<Types.expansion.Statistic>('/extension/statistic')
            return response.result
        }
    })
})

export const useGitHubStatisticStore = defineStore('githubStatistic', () => {
    return createFetchStore<Types.github.GitHubStatistic | null>({
        once: true,
        data: null,
        fetcher: () => tunnel.dispatch(TunnelModule.StatisticGitHubJson)
    })
})

export const useNpmStatisticStore = defineStore('npmStatistic', () => {
    return createFetchStore<Types.github.NpmStatistic | null>({
        once: true,
        data: null,
        fetcher: () => tunnel.dispatch<Types.github.NpmStatistic>(TunnelModule.StatisticNpmJson)
    })
})
