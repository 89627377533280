/**
 * Enums
 * @author Perlou(perloukevin@gmail.com)
 */

export enum GAEventCategories {
    Comment = 'comment',
    Share = 'share',
    Widget = 'widget',
    Universal = 'global',
    Article = 'page_article',
    Index = 'page_index',
    App = 'page_app',
    About = 'page_about',
    YouTube = 'page_youtube',
    Photography = 'page_photography'
}

export enum NodePressResponseStatus {
    Error = 'error',
    Success = 'success'
}

// article.origin
export enum OriginState {
    Original = 0, // 原创
    Reprint = 1, // 转载
    Hybrid = 2 // 混合
}

// comment.post_id
export enum CommentPostId {
    Guestbook = 0 // 留言板
}

// comment.pid
export enum CommentParentId {
    Self = 0 // `0` means no parent comment
}

export enum SortType {
    Asc = 1, // 升序
    Desc = -1, // 降序
    Hottest = 2 // 热序
}

// layout
export enum LayoutColumn {
    Normal = 0,
    Wide = 1, // 3 column
    Full = 2 // full page
}

// theme
export enum Theme {
    Light = 'light',
    Dark = 'dark'
}

export enum TunnelModule {
    WebFont = 'webfont',
    MyGoogleMap = 'my_google_map',
    TwitterProfile = 'twitter_profile',
    TwitterTweets = 'twitter_tweets',
    YouTubePlaylist = 'youtube_playlist',
    YouTubeVideoList = 'youtube_video_list',
    InstagramProfile = 'instagram_profile',
    InstagramMedias = 'instagram_medias',
    InstagramMediaChildren = 'instagram_media_children',
    InstagramCalendar = 'instagram_calendar',
    BingWallpaper = 'bing_wallpaper',
    NetEaseMusic = 'netease_music',
    DoubanMovies = 'douban_movies',
    GitHubSponsors = 'github_sponsors',
    GitHubContributions = 'github_contributions',
    StatisticGitHubJson = 'statistic_github_json',
    StatisticNpmJson = 'statistic_npm_json'
}

// router
export enum CategorySlug {
    Code = 'code',
    Insight = 'insight',
    Invest = 'invest'
}

export enum RouteName {
    Home = 'home',
    Article = 'article-detail',
    CategoryFlow = 'category-flow',
    TagFlow = 'tag-flow',
    DateFlow = 'date-flow',
    SearchFlow = 'search-flow',
    Archive = 'archive',
    Guestbook = 'guestbook',
    About = 'about',
    App = 'app',
    Sponsor = 'sponsor',
    Photography = 'photography',
    YouTube = 'youtube',
    Error = 'error'
}

export enum SocialMedia {
    Wechat = 'wechat',
    Weibo = 'weibo',
    Twitter = 'twitter',
    douban = 'douban',
    Evernote = 'evernote',
    Facebook = 'facebook',
    LinkedIn = 'linkedin'
}

export enum ArticleLang {
    Chinese = 'zh', // 简体中文
    English = 'en', // English
    Mixed = 'mix' // 多语言混合
}

export enum Language {
    English = 'en',
    Chinese = 'zh'
}

export enum UserType {
    Null = 0,
    Local = 1,
    Disqus = 2
}

export enum ProviderId {
    Alipay = 'alipay',
    WeChatPay = 'wechat-pay'
}

export enum CommentEvents {
    Sort = 'sort',
    Page = 'page',
    Vote = 'vote',
    Blossom = 'blossom',
    Delete = 'delete',
    Reply = 'reply',
    CancelReply = 'cancelReply',
    Submit = 'submit'
}
