import { useEnhancer } from '/@/app/enhancer'
import { useCDNDomain } from '/@/app/context'
import { useStores } from '/@/stores'
import { Language, LanguageKey } from '/@/language'
import { firstUpperCase } from '/@/transforms/text'
import { getAssetURL } from '/@/transforms/url'
import { META } from '/@/config/app.config'

export const useAdminAvatar = (avatar?: string) => {
    return avatar || getAssetURL(useCDNDomain(), '/images/anonymous.png')
}

export interface AboutI18nConfig {
    [Language.Chinese]: string
    [Language.English]: string
}

export const i18ns = {
    footprint: {
        [Language.Chinese]: '路为纸，地成册，行作笔，心当墨；思无界，行有疆',
        [Language.English]: 'Every path i went astray built up my Rome.'
    },
    sponsor: {
        [Language.Chinese]: '随喜赞助',
        [Language.English]: 'Sponsor'
    },
    statement: {
        [Language.Chinese]: '众而周知',
        [Language.English]: 'Statement'
    },
    feedback: {
        [Language.Chinese]: '向我反馈',
        [Language.English]: 'Feedback'
    },
    archive: {
        [Language.Chinese]: '笔文存档',
        [Language.English]: 'Archive'
    },
    photography: {
        [Language.Chinese]: '行行摄摄',
        [Language.English]: 'PhotoGram'
    },
    guestbook: {
        [Language.Chinese]: '给我留言',
        [Language.English]: 'Guestbook'
    },
    nft: {
        [Language.Chinese]: '数字藏品',
        [Language.English]: 'NFTs'
    },
    rss: {
        [Language.Chinese]: '长期订阅',
        [Language.English]: 'Subscribe'
    },
    discordGroup: {
        [Language.Chinese]: '国际联谊',
        [Language.English]: 'Discord'
    },
    telegramGroup: {
        [Language.Chinese]: '自由报社',
        [Language.English]: 'TG Group'
    },
    biography: {
        [Language.Chinese]: [
            `嗨！我是 Kevin，一名靠摸爬滚打自学的前端工程师，后端略懂，半吊子全栈开发者，有一定的产品以及项目管理能力。`,
            `在不远的未来，我可能会制作一些属于自己的独立产品，希望得到你的关注和支持。`,
            `如果我的任何输出帮助了你，也期待你的慷慨赞助。`,
            `我把这里称之为自己的数字花园，主要输出一些自己的知识沉淀和生活随笔，祝你在这儿玩得愉快！`,
            `想要联系我的话，可以发邮件到 perloukevin@gmail.com`
        ].join(''),
        [Language.English]: [
            `Hi! I'm Kevin, a software engineer.`,
            `In the near future, I may make some independent products of my own, and I hope to get your attention and support.`,
            `If any of my output helps you, I also look forward to your generous donation.`,
            `I call this my own digital garden, where I mainly output some of my own knowledge and life essays. I hope you have a good time here.`,
            `If you want to contact me, you can send an email to perloukevin@gmail.com`
        ].join(' ')
    }
}

export const useAboutPageMeta = () => {
    const { i18n, seoMeta, isZhLang } = useEnhancer()
    const { adminInfo } = useStores()

    return seoMeta(() => {
        const enTitle = firstUpperCase(i18n.t(LanguageKey.PAGE_ABOUT, Language.English)!)
        const titles = isZhLang.value ? [i18n.t(LanguageKey.PAGE_ABOUT), enTitle] : [enTitle]
        const description = `${isZhLang.value ? '关于' : 'About'} ${META.author}`
        return {
            pageTitle: titles.join(' | '),
            description,
            ogType: 'profile',
            ogImage: adminInfo.data?.avatar
        }
    })
}

export const SPECIAL_LINKS = Object.freeze([
    {
        name: 'GitHub',
        url: 'https://github.com'
    },
    {
        name: `Vite`,
        url: 'https://vitejs.dev/'
    },
    {
        name: `Disqus`,
        url: 'https://disqus.com/'
    }
])
