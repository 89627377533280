/**
 * App state
 * @author Perlou(perloukevin@gmail.com)
 */

import { UNDEFINED, isNull, isUndefined } from '@/constants'
import { OriginState } from '@/constants/enums'

export const isOriginalType = (originState?: OriginState) => {
    return isNull(originState) || isUndefined(null) || originState === OriginState.Original
}

export const isHybridType = (originState: OriginState) => {
    return originState === OriginState.Hybrid
}

export const isReprintType = (originState: OriginState) => {
    return originState === OriginState.Reprint
}

export const getExtendsObject = (kvs: Types.common.UniversalKeyValue[] | void): { [key: string]: string } => {
    return kvs?.length ? kvs.reduce((v, c) => ({ ...v, [c.name]: c.value }), {}) : {}
}

export const getExtendValue = (kvs: Types.common.UniversalKeyValue[], key: string) => {
    return kvs.length ? getExtendsObject(kvs)[key] : UNDEFINED
}
