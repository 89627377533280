/**
 * Media state
 * @author Perlou(perloukevin@gmail.com)
 */

import { defineStore } from 'pinia'
import { createFetchStore } from './_fetch'
import { TunnelModule } from '/@/constants/enums'
import { isClient } from '/@/app/environment'
import { delayPromise } from '/@/utils/delayer'
import tunnel from '/@/services/tunnel'

// Douban movies
export const useDoubanMoviesStore = defineStore('doubanMovies', () => {
    return createFetchStore({
        once: true,
        data: null,
        fetcher: () => tunnel.dispatch(TunnelModule.DoubanMovies)
    })
})

// Twitter profile
export const useTwitterProfileStore = defineStore('twitterProfile', () => {
    return createFetchStore<Types.twitter.TwitterProfile | null>({
        data: null,
        fetcher: () => tunnel.dispatch<Types.twitter.TwitterProfile>(TunnelModule.TwitterProfile)
    })
})

// Twitter latest tweets
export const useTwitterLatestTweetsStore = defineStore('twitterLatestTweets', () => {
    return createFetchStore<Types.twitter.TwitterTweetListResponse | null>({
        data: null,
        fetcher(params?: any) {
            return tunnel.dispatch<Types.twitter.TwitterTweetListResponse>(TunnelModule.TwitterTweets, params)
        }
    })
})

// Instagram profile
export const useInstagramProfileStore = defineStore('instagramProfile', () => {
    return createFetchStore<Types.instagram.InstagramProfile | null>({
        data: null,
        fetcher: () => tunnel.dispatch<Types.instagram.InstagramProfile>(TunnelModule.InstagramProfile)
    })
})

// Instagram latest medias
export const useInstagramLatestMediasStore = defineStore('instagramLatestMedias', () => {
    return createFetchStore<Types.instagram.InstagramMediaListResponse | null>({
        data: null,
        fetcher: () => {
            const request = tunnel.dispatch<Types.instagram.InstagramMediaListResponse>(
                TunnelModule.InstagramMedias
            )
            return isClient ? delayPromise(480, request) : request
        }
    })
})

// YouTube playlist
// export const useYouTubePlayListStore = defineStore('youtubePlaylist', () => {
//     return createFetchStore<Array<any>>({
//         data: [],
//         async fetcher() {
//             const response = await tunnel.dispatch<Array<any>>(TunnelModule.YouTubePlaylist)
//             response.sort((a, b) => a.snippet.position - b.snippet.position)
//             return response
//         }
//     })
// })

// My Google map
export const useMyGoogleMapStore = defineStore('myGoogleMap', () => {
    return createFetchStore({
        once: true,
        data: null,
        fetcher: () => tunnel.dispatch(TunnelModule.MyGoogleMap)
    })
})
