/**
 * App config
 * @author Perlou(perloukevin@gmail.com)
 */

export const DEFAULT_DELAY = 468
export const IMAGE_SHARE_LONG_ARTICLE_THRESHOLD = 6688
export const RENDER_LONG_ARTICLE_THRESHOLD = 16688

export const META = Object.freeze({
    title: 'Perlou.top',
    zh_sub_title: '斯是陋室，唯吾芳馨',
    en_sub_title: `Perlou's digital garden`,
    zh_description: '慢即是快，欲速则不达',
    en_description: 'Slow is fast, haste makes waste',
    url: 'https://perlou.top',
    domain: 'perlou.top',
    author: 'Perlou',
    primary: '#0088f5'
})

// export const GEO_INFO = Object.freeze({
//     zh_title: '长居亚洲，游走热带',
//     en_title: 'UTC +07:00 ~ +09:00',
//     coordinates: [103.830391822121, 1.340863]
// })

// export const MAPBOX_CONFIG = Object.freeze({
//     // readonly token
//     TOKEN: 'pk.eyJ1Ijoic3VybW9uIiwiYSI6ImNsNDE4YmkzNjB2Z2wzY3F5dWg2M2tqeWIifQ.JhgYGFI4zsuNiX9dH-pBDg',
//     STYLE_LIGHT: 'mapbox://styles/surmon/cl41fktzn000f14pet94oo1u4',
//     STYLE_DARK: 'mapbox://styles/surmon/cl41gy1qo000l15ry20j5ae0k',
//     ZOOM: 12.4374,
//     CENTER: [103.830391822121, 1.348463]
// })

export const IDENTITIES = Object.freeze({
    GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-CTWSKJMNN4',
    GOOGLE_ADSENSE_CLIENT_ID: 'TODO',
    SENTRY_PUBLIC_DSN: 'TODO',
    MUSIC_163_BGM_ALBUM_ID: '12441328056',
    DOUBAN_USER_ID: '254174630',
    GITHUB_USER_NAME: 'Perlou',
    INSTAGRAM_USERNAME: 'perlou666',
    TWITTER_USER_NAME: 'perlou666',
    TWITTER_USER_ID: '1501103928561209347'
})

export const VALUABLE_LINKS = Object.freeze({
    RSS: '/rss.xml',
    SITE_MAP: '/sitemap.xml',
    UPTIME_STATUS: 'https://redirect.perlou.top/status',
    NPM_HOMEPAGE: 'https://www.npmjs.com/~perlou',
    GITHUB: `https://github.com/${IDENTITIES.GITHUB_USER_NAME}`,
    MARKDOWN: 'https://daringfireball.net/projects/markdown/',
    GOOGLE_MY_MAP: `https://www.google.com/maps/d/embed?mid=1KD_kBdtmrDaOej1mGEVEN4ObKNJJQi0&z=3`,
    GOOGLE_MY_MAP_KML: `https://www.google.com/maps/d/u/0/kml?forcekml=1&mid=1KD_kBdtmrDaOej1mGEVEN4ObKNJJQi0`,
    MUSIC_163: `https://music.163.com/#/playlist?id=${IDENTITIES.MUSIC_163_BGM_ALBUM_ID}`,
    DOUBAN: 'https://www.douban.com/people/perlou',
    DOUBAN_MOVIE: `https://movie.douban.com/people/perlou/collect`,
    INSTAGRAM: `https://www.instagram.com/${IDENTITIES.INSTAGRAM_USERNAME}`,
    TWITTER: `https://twitter.com/${IDENTITIES.TWITTER_USER_NAME}`
})
