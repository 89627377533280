/**
 * Announcement state
 * @author Perlou(oerloukevin@gmail.com)
 */
import { defineStore } from 'pinia'
import { createFetchStore } from './_fetch'
import nodepress from '/@/services/nodepress'

export const useAnnouncementStore = defineStore('announcement', () => {
    return createFetchStore<Types.announcement.Announcement[]>({
        data: [],
        preclean: true,
        async fetcher(params?: any) {
            const response = await nodepress.get<Types.common.PaginationList<Types.announcement.Announcement>>(
                '/announcement',
                { params }
            )
            return response.result.data
        }
    })
})
