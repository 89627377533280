/**
 * Category state
 * @author Perlou(perloukevin@gmail.com)
 */

import { defineStore } from 'pinia'
import { createFetchStore } from './_fetch'
import nodepress from '/@/services/nodepress'

export const useCategoryStore = defineStore('category', () => {
    return createFetchStore<Types.category.Category[]>({
        data: [],
        once: true,
        fetcher() {
            return nodepress.get<Types.category.Category[]>('/category/all').then((response) => {
                return response.result
            })
        }
    })
})
