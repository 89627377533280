/**
 * Identity state
 * @author Perlou(perloukevin@gmail.com)
 */

import { defineStore } from 'pinia'
import { reactive, computed, watch } from 'vue'
import { getJSON, setJSON, remove } from '/@/utils/storage'
// import nodepress from '/@/services/nodepress'
import { UserType } from '@/constants/enums'

const LOCAL_STORGAE_KEY = 'identity-state'

export const useIdentityStore = defineStore('identity', () => {
    // disqus TODO
    // const disqusConfig = shallowRef<any>(null)
    // user
    const user = reactive({
        type: UserType.Null,
        localProfile: null as Types.comment.Author | null,
        disqusProfile: null as any | null
    })
    // vote history
    const vote = reactive({
        likedPages: [] as number[],
        likedComments: [] as number[],
        dislikedComments: [] as number[]
    })
    // feedback history
    const feedbacks = reactive<any[]>([])

    // getters
    const isLikedPage = computed(() => (id: number) => vote.likedPages.includes(id))
    const isLikedComment = computed(() => (id: number) => vote.likedComments.includes(id))
    const isDislikedComment = computed(() => (id: number) => vote.dislikedComments.includes(id))

    const author = computed(() => {
        if (user.type === UserType.Local) {
            return user.localProfile
        }
        // disqus TODO
        // if (user.type === UserType.Disqus) {
        //     return user.disqusProfile
        // }
        return null
    })

    const likeComment = (commentId: number) => {
        vote.likedComments.push(commentId)
    }

    const dislikeComment = (commentId: number) => {
        vote.dislikedComments.push(commentId)
    }

    const likePage = (id: number) => {
        vote.likedPages.push(id)
    }

    const addFeedback = (data) => {
        feedbacks.push(data)
    }

    const saveLocalUser = (profile: Types.comment.Author) => {
        user.localProfile = { ...profile }
        user.type = UserType.Local
    }

    const removeLocalUser = () => {
        user.type = UserType.Null
        user.localProfile = null
    }

    // disqus TODO
    //   const fetchDisqusLogout = async () => {
    //     await nodepress.get('/disqus/oauth-logout')
    //     user.type = UserType.Null
    //     user.disqusProfile = null
    //   }

    //   const fetchDisqusUserInfo = async () => {
    //     const response = await nodepress.get<any>('/disqus/user-info')
    //     user.disqusProfile = response.result
    //     user.type = UserType.Disqus
    //   }

    //   const fetchDisqusConfig = async () => {
    //     const response = await nodepress.get<string>('/disqus/config')
    //     disqusConfig.value = response.result
    //   }

    const getStoreState = () => ({
        user,
        vote,
        feedbacks
    })

    const setStoreState = (state: Types.user.IdentityState) => {
        // disqusConfig.value = state.disqusConfig
        user.type = state.user.type
        user.localProfile = state.user.localProfile
        user.disqusProfile = state.user.disqusProfile
        vote.likedPages = state.vote.likedPages
        vote.likedComments = state.vote.likedComments
        vote.dislikedComments = state.vote.dislikedComments
        feedbacks.splice(0, feedbacks.length, ...state.feedbacks)
    }

    const resetStateFromStorage = () => {
        try {
            const localState = getJSON<Types.user.IdentityState>(LOCAL_STORGAE_KEY)
            if (localState) {
                setStoreState(localState)
            }
        } catch (error) {
            remove(LOCAL_STORGAE_KEY)
        }
    }

    const initOnClient = () => {
        // 1. init disqus config
        // fetchDisqusConfig()
        // 2. bind client storage
        // 2.1 init state from storage
        resetStateFromStorage()
        // 2.2 bind state to storage
        watch(
            () => getStoreState(),
            (state) => setJSON(LOCAL_STORGAE_KEY, state),
            { deep: true }
        )
        // 2.3 reset state when storage changed
        window.addEventListener('storage', (event) => {
            if (event.key === LOCAL_STORGAE_KEY) {
                resetStateFromStorage()
            }
        })
        // 3. init and reset disqus cache
        if (user.type === UserType.Disqus) {
            //   fetchDisqusUserInfo().catch(() => {
            //     user.disqusProfile = null
            //     user.type = UserType.Null
            //   })
        } else {
            user.disqusProfile = null
        }
    }

    return {
        // disqusConfig,
        user,
        vote,
        feedbacks,
        author,
        isLikedPage,
        isLikedComment,
        isDislikedComment,
        likeComment,
        dislikeComment,
        likePage,
        addFeedback,
        saveLocalUser,
        removeLocalUser,
        // fetchDisqusLogout,
        // fetchDisqusUserInfo,
        // fetchDisqusConfig,
        initOnClient
    }
})
