/**
 * Comment state
 * @author Perlou(perloukevin@gmail.com)
 */

import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import nodepress from '/@/services/nodepress'
import { isClient } from '/@/app/environment'
import { SortType, CommentParentId } from '/@/constants/enums'
import { UNDEFINED } from '/@/constants'
import { delayPromise } from '/@/utils/delayer'
import { useIdentityStore } from './identity'

export const COMMENT_API_PATH = '/comment'

export const useCommentStore = defineStore('comment', () => {
    const fetching = ref(false)
    const posting = ref(false)
    const deleting = ref(false)
    const comments = ref<Types.comment.Comment[]>([])
    const pagination = ref<Types.common.Pagination | null>(null)

    const commentTreeList = computed<Types.comment.CommentTreeItem[]>(() => {
        // only keep 2 level tree
        const ids = comments.value.map((comment) => comment.id)
        const roots = comments.value.filter((comment) => {
            return comment.pid === CommentParentId.Self || !ids.includes(comment.pid)
        })
        const children = comments.value.filter((comment) => {
            return comment.pid !== CommentParentId.Self && ids.includes(comment.pid)
        })
        const fullMap = new Map<number, Types.comment.Comment>(
            comments.value.map((comment) => [comment.id, comment])
        )
        const treeMap = new Map<number, { comment: Types.comment.Comment; children: Array<Types.comment.Comment> }>(
            roots.map((comment) => [comment.id, { comment, children: [] }])
        )

        const findRootParentId = (pid: number): number | void => {
            const target = fullMap.get(pid)
            return !target
                ? UNDEFINED
                : target.pid === CommentParentId.Self
                  ? target.id
                  : findRootParentId(target.pid)
        }

        children.forEach((comment) => {
            const rootPid = findRootParentId(comment.pid)
            if (rootPid) {
                if (treeMap.has(rootPid)) {
                    const target = treeMap.get(rootPid)!
                    treeMap.set(rootPid, { ...target, children: [comment, ...target.children] })
                }
            }
        })

        return Array.from(treeMap.values())
    })

    const clearList = () => {
        comments.value = []
        pagination.value = null
    }

    const fetchList = async (params: Types.comment.CommentFetchParams = {}, loadmore = false) => {
        params = {
            page: 1,
            per_page: 50,
            sort: SortType.Desc,
            ...params
        }

        // clear list when refetch
        if (params.page === 1) {
            clearList()
        }

        try {
            fetching.value = true
            const request = nodepress.get<Types.common.PaginationList<Types.comment.Comment>>(COMMENT_API_PATH, {
                params
            })
            const response = await (isClient ? delayPromise(480, request) : request)
            pagination.value = response.result.pagination
            if (loadmore) {
                comments.value.push(...response.result.data)
            } else {
                comments.value = response.result.data
            }
        } finally {
            fetching.value = false
        }
    }

    const postComment = async (comment: Partial<Types.comment.Comment>) => {
        try {
            posting.value = true
            const response = await nodepress.post<Types.comment.Comment>('/disqus/comment', comment)
            comments.value.unshift(response.result)
            if (pagination.value) {
                pagination.value.total++
            }
            return response.result
        } finally {
            posting.value = false
        }
    }

    const deleteComment = async (commentId: number) => {
        try {
            deleting.value = true
            await nodepress.delete('/disqus/comment', { data: { comment_id: commentId } })
            const index = comments.value.findIndex((comment) => comment.id === commentId)
            if (index > -1) {
                comments.value.splice(index, 1)
                pagination.value!.total--
            }
        } finally {
            deleting.value = false
        }
    }

    const postCommentVote = async (commentId: number, vote: 1 | -1) => {
        const identityStore = useIdentityStore()
        await nodepress.post(`/vote/comment`, { comment_id: commentId, vote, author: identityStore.author })
        const comment = comments.value.find((comment) => comment.id === commentId)
        if (comment) {
            vote > 0 ? comment.likes++ : comment.dislikes++
        }
    }

    return {
        comments,
        pagination,
        fetching,
        posting,
        deleting,
        commentTreeList,
        clearList,
        fetchList,
        postComment,
        deleteComment,
        postCommentVote
    }
})
