/**
 * Swiper for vue
 * @author Perlou(perloukevin@gmail.com)
 */

import { Swiper as SwiperClass } from 'swiper'
import { Autoplay, Mousewheel, Grid, EffectFade } from 'swiper/modules'

SwiperClass.use([Autoplay, Mousewheel, Mousewheel, Grid, EffectFade])

export default SwiperClass
export { Swiper, SwiperSlide } from 'swiper/vue'
