<script lang="ts" setup>
    import { computed } from 'vue'
    import { SponsorState } from './state'

    interface Props {
        state: SponsorState
        maxSponsors?: number
    }

    const props = withDefaults(defineProps<Props>(), {
        maxSponsors: 19
    })

    const activeProvider = computed(() => props.state.activeProvider.value)
</script>

<template>
    <div class="sponsor-provider" :class="activeProvider.id">
        <uimage class="qrcode" v-if="activeProvider.qrcode" :src="activeProvider.qrcode" cdn />
    </div>
</template>

<style lang="scss" scoped>
    @import '/src/styles/variables.scss';
    @import '/src/styles/mixins.scss';

    .sponsor-provider {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 18rem;

        .external {
            margin-bottom: $gap;
            color: $color-text-secondary;

            .link {
                margin-left: $gap-sm;
                cursor: pointer;
                text-decoration: underline;
                color: $color-text-secondary;
                &:hover {
                    color: $color-link;
                }
            }

            .iconfont {
                font-size: $font-size-small;
                color: $color-text-secondary;
                margin-left: $gap-xs;
            }
        }

        .qrcode {
            height: 14rem;
            max-height: 60%;
            border-radius: $radius-xs;
        }
    }
</style>
