/**
 * constants
 * @author Perlou(perloukevin@gmail.com)
 */

import { ArticleLang, Language } from './enums'
// import { Language } from '/@/language'

// anchor
export const HEADER_ELEMENT_ID = 'A_header'
export const NAV_ELEMENT_ID = 'A_nav'
export const MAIN_ELEMENT_ID = 'A_main'
export const MAIN_CONTENT_ELEMENT_ID = 'A_main'
export const ASIDE_ELEMENT_ID = 'A_aside'
export const FOOTER_ELEMENT_ID = 'A_footer'

export const ARTICLE_CONTENT_ELEMENT_ID = 'A_article_content'
export const ARTICLE_READMORE_ELEMENT_ID = 'A_article_readmore'
export const ARTICLE_META_ELEMENT_ID = 'A_article_meta'
export const ARTICLE_SHARE_ELEMENT_ID = 'A_article_share'
export const ARTICLE_RELATED_ELEMENT_ID = 'A_article_related'

export const ARTICLE_CONTENT_HEADING_ELEMENT_ID_PREFIX = 'A_article_content_heading'
export const getArticleContentHeadingElementId = (level: number, anchor: string) => {
    return `${ARTICLE_CONTENT_HEADING_ELEMENT_ID_PREFIX}_${level}_${anchor}`
}

export const getArticleHeadingUrlHash = (heading: string) => {
    return heading
        .replace(/[^\p{L}\d\s\-_]/gu, '')
        .toLowerCase()
        .replace(/\s+/g, '-')
}

export const COMMENT_ELEMENT_ID = 'A_comment_warpper'
export const COMMENT_PUBLISHER_ELEMENT_ID = 'A_comment_publisher'
export const COMMENT_REPLY_PUBLISHER_ELEMENT_ID = 'A_comment_reply_publisher'
export const COMMENT_FOOTER_ELEMENT_ID = 'A_comment_footer'
export const COMMENT_ITEM_ELEMENT_ID_PREFIX = 'A_comment_content_item'
export const getCommentItemElementId = (commentId: string | number) => {
    return `${COMMENT_ITEM_ELEMENT_ID_PREFIX}_${commentId}`
}

export const COMMENTS_URL_HASH = 'comments'
export const COMMENT_ITEM_URL_HASH_PREFIX = 'comment-'

export const getCommentIdByUrlHash = (hash: string) => {
    return hash.replace(COMMENT_ITEM_URL_HASH_PREFIX, '')
}

export const getCommentUrlHashById = (commentId: string | number) => {
    return `${COMMENT_ITEM_URL_HASH_PREFIX}${commentId}`
}

// http-code
export const SUCCESS = 200
export const BAD_REQUEST = 400
export const FORBIDDEN = 403
export const NOT_FOUND = 404
export const INVALID_ERROR = 500

// value
export const NULL = null
export const UNDEFINED = void 0

export const isNull = (value): value is null => value === NULL
export const isUndefined = (value): value is void => value === UNDEFINED
export const isNil = (value): value is null | void => isNull(value) || isUndefined(value)

// theme
export const THEME_STORAGE_KEY = 'theme'

// article
export const ArticleLangI18n: Record<ArticleLang, Record<Language, string>> = {
    [ArticleLang.Chinese]: {
        [Language.Chinese]: '中文',
        [Language.English]: 'ZH'
    },
    [ArticleLang.English]: {
        [Language.Chinese]: '英文',
        [Language.English]: 'EN'
    },
    [ArticleLang.Mixed]: {
        [Language.Chinese]: '多语',
        [Language.English]: 'MIX'
    }
}
